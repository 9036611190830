import { action, computed, makeObservable, observable } from "mobx";
import { AlertModel } from "models/AlertModel";
import AlertService from "services/AlertService";

export class AlertStore {

    @observable
    private _alerts: AlertModel[] | undefined = undefined;

    constructor() {
        makeObservable(this);
    }

    @computed
    get alerts(): AlertModel[] {
        if (this._alerts === undefined) {
            this.updateAlerts();
            this.setAlertTimeout();
        }
        return this._alerts || [];
    }

    @action.bound
    public async updateAlerts() {
        AlertService.getAll()
            .then(values => this.setAlerts(values));
    }
    
    private setAlertTimeout() {
        setTimeout(() => {
            this.updateAlerts();
            this.setAlertTimeout();
        }, 604800000);
    }

    @computed
    get isReady(): boolean {
        return this._alerts !== undefined;
    }

    @action
    private setAlerts(alertModels: AlertModel[] | undefined) {
        this._alerts = alertModels;
    }
}
